<template>
  <section class="dashboard">
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Facturas</h4>
            <b-table
              responsive
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <!-- A virtual composite column -->
              <template #cell(factura)="data">
                <router-link
                  :to="'/sales/' + data.item.numero + '|' + data.item.serie"
                >
                  {{ data.item.serie + "|" + data.item.numero }}</router-link
                >
              </template>
              <template #cell(totalbruto)="data">
                {{ numeral(data.item.totalbruto).format("$ 0,0.00") }}
              </template>
              <template #cell(totalneto)="data">
                {{ numeral(data.item.totalneto).format("$ 0,0.00") }}
              </template>
            </b-table>
            <p>
              <b-pagination
                v-model="currentPage"
                :total-rows="items.length / perPage"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import numeral from "numeral";
import { mapGetters } from "vuex";
export default {
  name: "sales",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "factura",
          label: "# Factura",
        },
        {
          key: "mesa",
          label: "Mesa",
          sortable: true,
        },
        {
          key: "fecha",
          label: "Fecha",
          sortable: true,
        },
        {
          key: "vendedor",
          label: "Vendedor",
          sortable: true,
        },
        {
          key: "totalbruto",
          label: "Total Bruto",
          sortable: true,
        },
        {
          key: "totalneto",
          label: "Total Neto",
          sortable: true,
        },
      ],
      items: [],
    };
  },
  created() {
    this.getTransactions();
    if (this.getUser) {
      this.$store.dispatch("setEvent", { name: "open_sales" });
    }
  },
  methods: {
    getTransactions() {
      axios("/api/reports/transacts", {
        params: {
          fromDate: this.getOpenDate,
          toDate: this.getCloseDate,
          caja: this.getCaja,
        },
      }).then((res) => {
        this.items = res.data.data;
      });
    },
    numeral,
  },
  computed: {
    ...mapGetters(["getOpenDate", "getCloseDate", "getCaja", "getUser"]),
  },
  watch: {
    getOpenDate() {
      this.getTransactions();
    },
  },
};
</script>